<template>
  <label :id="btnId">{{label}}</label>
</template>
<script>
  import AjaxUpload from './upload/AjaxUpload'

  export default {
    name: 'UploadBtn',
    data () {
      return {
        btnId: '_uploadBtn' + new Date().getTime() + Math.floor(Math.random() * 1000)
      }
    },
    props: {
      label: {
        type: String,
        default: '点击上传'
      },
      url: String,
      types: {
        type: String,
        default: '*'
      },
      size: {
        type: String,
        default: '1MB'
      },
      name: {
        type: String,
        default: 'file'
      },
      postData: {
        type: Object,
        default: function () {
          return {}
        }
      },
      uploading: {
        type: Function,
        default: function (bytesComplete, bytesTotal) {
          if (bytesTotal > 0) {
            document.getElementById(this.btnId).innerHTML = '正在上传' + Math.round(bytesComplete * 100 / bytesTotal) + '%'
          }
        }
      },
      fileDialogComplete: {
        type: Function,
        default: function () {
          return true
        }
      },
      multiple: Boolean
    },
    methods: {
      fileTypeError: function () {
        return '仅支持以下文件类型上传：' + this.types
      },
      uploadSuccess: function (url) {
        document.getElementById(this.btnId).innerHTML = this.label
        this.$emit('done', url)
      },
      uploadFail: function (error) {
        document.getElementById(this.btnId).innerHTML = this.label
        this.$message.error(error)
      }
    },
    mounted: function () {
      let self = this
      let settings = {
        'upload_url': this.url,
        'post_params': this.postData,
        'use_query_string': false,
        'file_size_limit': this.size,
        'file_types': this.types,
        'file_post_name': this.name,
        'file_types_description': this.fileTypeError(),
        'file_upload_limit': 0,
        'file_queue_limit': 0,
        'prevent_swf_caching': false,
        'debug': false,
        'button_width': '150',
        'button_height': '32',
        'button_placeholder': document.getElementById(this.btnId),
        'button_text': '<a class="theFont">' + this.label + '</a>',
        'button_text_style': '.theFont{font-size:14;color:#333;}',
        'button_text_left_padding': 14,
        'button_text_top_padding': 5,
        'multiple': this.multiple,
        'button_image_url': '',
        'button_cursor': '-1',
        'button_window_mode': 'transparent',
        // The event handler functions are defined in handlers.js
        'file_queued_handler': function () {

        },
        'file_queue_error_handler': function (file, errorCode) {
          switch (errorCode) {
            case AjaxUpload.QUEUE_ERROR.INVALID_FILE_TYPE:
              self.uploadFail(self.fileTypeError())
              break
            case AjaxUpload.QUEUE_ERROR.FILE_EXCEEDS_SIZE_LIMIT:
              self.uploadFail('文件大小不能超过' + self.size)
              break
            default:
              self.uploadFail('上传失败')
              break
          }
        },
        'file_dialog_complete_handler': function (numFilesSelected, numFilesQueued, numFilesInQueue) {
          if (self.fileDialogComplete(numFilesSelected, numFilesQueued, numFilesInQueue)) {
            this.startUpload()
          }
        },
        'upload_progress_handler': function (file, bytesComplete, bytesTotal) {
          self.uploading(bytesComplete, bytesTotal)
        },
        'upload_error_handler': function (file, errorCode, message) {
          self.uploadFail(message)
        },
        'upload_success_handler': function (file, responseTxt) {
          let ret = JSON.parse(responseTxt)
          if (ret['code'] !== '00') {
            self.uploadFail(ret['msg'])
          } else {
            // 兼容之前的项目， 新项目直接用ret
            if (ret['url']) {
              self.uploadSuccess(ret['url'])
            } else if (ret['data']) {
              self.uploadSuccess(ret['data'])
            } else {
              self.uploadSuccess(ret)
            }
          }
        },
        upload_complete_handler: function () {
          this.startUpload()
        }
      }
      AjaxUpload.createUploader(settings)
    }
  }
</script>
