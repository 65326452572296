<template>
  <el-form ref="projectForm" :rules="rules" :model="model" method="post" id="projectForm"
           onsubmit="return false" label-width="80px">

    <el-form-item prop="projectType" label="项目类型">

      <el-select v-model="model.projectType" placeholder="请选择">
        <el-option
                v-for="(name,value) in ProjectTypeMap"
                :key="value"
                :label="name"
                :value="value">
        </el-option>
      </el-select>

    </el-form-item>


    <el-form-item prop="projectBudget" label="项目预算">
      <el-input v-model="model.projectBudget" style="width: 215px"></el-input>
      <span class="info ml10">元</span>
    </el-form-item>

    <el-form-item prop="projectName" label="项目名称">
      <el-input v-model="model.projectName" show-word-limit maxlength="64"></el-input>
    </el-form-item>


    <el-form-item prop="projectName" label="项目简介">
      <el-input v-model="model.projectIntro" type="textarea" :rows="7" show-word-limit maxlength="255"></el-input>
    </el-form-item>

    <el-form-item prop="demandAttach" label="需求文档">

      <upload-btn
              class="el-button el-button--info is-plain el-button--small"
              :url="uploadUrl" @done="url => model.demandAttach= url"
              types="*.jpg;*.png;*.zip;*.doc;*.docx;*.pdf" size="5MB"
      />
      <span class="info ml10">支持格式：word文档，PDF文档，zip压缩包，图片，大小不能超过5M</span>
      <div>
        <a :href="model.demandAttach" target="_blank" v-show="model.demandAttach"><i class="el-icon-paperclip"></i>需求说明</a>
        <input type="hidden" name="demandAttach" id="demandAttach" v-model="model.demandAttach">
      </div>

    </el-form-item>


    <el-form-item>
      <el-button type="primary" :loading="loading" @click="submitForm('projectForm')">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import ProjectTypeMap from './ProjectTypeMap'
  import UploadBtn from '../common/UploadBtn'
  import objectUtil from 'tdw-object-util'

  export default {
    name: 'ProjectForm',
    components: {UploadBtn},
    data () {
      return {
        uploadUrl: 'https://upload.gdkit.cn/index.php?app=gdkit&category=demandAttach',
        loading: false,
        ProjectTypeMap,
        rules: {
          projectType: [
            {required: true, message: '请选择项目类型', 'trigger': 'blur'}
          ],
          projectBudget: [
            {required: true, message: '请输入预算', 'trigger': 'blur'},
            {pattern: /^[1-9]\d*$/, message: '预算金额只能是整数', trigger: 'blur'}
          ],
          projectName: [
            {required: true, message: '请输入项目名称', 'trigger': 'blur'},
            {max: 64, message: '项目名称长度超过限制', 'trigger': 'blur'}
          ],
          projectIntro: [
            {required: true, message: '请输入项目简介', 'trigger': 'blur'},
            {max: 255, message: '项目简介长度超过限制', 'trigger': 'blur'}
          ]
        }
      }
    },
    props: {
      model: {
        type: Object,
        default () {
          return {
            projectType: '',
            projectBudget: '',
            projectName: '',
            projectIntro: '',
            demandAttach: ''
          }
        }
      },
      url: String
    },
    methods: {
      submitForm (formName) {
        const self = this
        self.$refs[formName].validate((valid) => {
          if (!valid) {
            return false
          }
          self.loading = true
          const data = objectUtil.copy(self.model)
          data.projectBudget *= 100
          self.loading = true
          self.$http.post({
            url: self.url,
            data,
            success () {
              self.$emit('success')
            },
            always () {
              self.loading = false
            }
          })
        })
      }
    }
  }
</script>

<style scoped>

</style>