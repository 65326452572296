function Html5Upload (settings, AjaxUpload) {
  this.upload_url = "";
  this.post_params = {};

  this.file_types = "*";

  this.file_queue_limit = 0;

  this.multiple = true;

  this.debug = true;

  this.button_placeholder_id = "";
  this.button_placeholder = null;
  this.file_post_name = "Filedata";

  this.inputElement = null;
  this.buttonElement = null;
  this.formElement = null;

  this.queue = [];

  this.numFilesQueued = 0;
  this.numFilesSelected = 0;

  this.fileSizeLimit = '2 MB';

  this.fileSizeLimitBytes = 2097152;

  this.uploading = false;

  this.instance_id = 0;

  this.file_dialog_start_handler = function () {

  };

  this.file_queued_handler = function (file) {
    this.log(file.name + " add to queue");
  };

  this.file_queue_error_handler = function (file, errorCode, message) {
    this.log(file.name + ":" + errorCode + ":" + message);
  };

  //选择完文件
  this.file_dialog_complete_handler = function (numFilesSelected, numFilesQueued, numFilesInQueue) {
    this.log("file_dialog_complete_handler:" + numFilesSelected + "/" + numFilesQueued + "/" + numFilesInQueue);
    this.startUpload();
  };

  this.upload_start_handler = function (file) {
    this.log(file.name + " start uploading");
  };


  this.upload_progress_handler = function (file, bytesComplete, bytesTotal) {
    this.log(file.name + Math.round(bytesComplete * 100 / bytesTotal) + "%");
  };

  this.upload_error_handler = function (file, errorCode, message) {
    this.log(file.name + ",errorCode:" + errorCode + ",message:" + message);
  };

  //客户端上传完成
  this.upload_complete_handler = function (file) {
    this.log(file.name + "  upload complete");
    this.startUpload();
  };

  //服务端返回响应
  this.upload_success_handler = function (file, responseTxt) {
    this.log(file.name + ", responseTxt:" + responseTxt);
  };

  this.init = function (settings) {
    for (let i in settings) {
      this[i] = settings[i]
    }

    this.buttonElement = this.button_placeholder || document.getElementById(this.button_placeholder_id);
    this.setFileLimitSize(this.fileSizeLimit);
    this.setfile_types(this.file_types);
    this.createFileInput();
  };

  this.setFileLimitSize = function (size) {
    this.fileSizeLimit = size.toUpperCase();
    let pattern = /(\d+)\s*(KB|MB|GB)/;
    let result = pattern.exec(this.fileSizeLimit);
    if (result) {
      if (result[2] === 'GB') {
        this.fileSizeLimitBytes = result[1] * 1073741824;
      } else if (result[2] === 'MB') {
        this.fileSizeLimitBytes = result[1] * 1048576;
      } else {
        this.fileSizeLimitBytes = result[1] * 1024;
      }
    }
  };

  this.setfile_types = function (type) {
    this.file_types = type.replace(/;/g, '|').replace(/\*\./g, '').toLowerCase();
  };

  this.createFileInput = function () {
    let self = this;

    this.formElement = document.createElement("form");
    this.formElement.style.left = "-9999px";
    this.formElement.style.top = "-9999px";
    this.formElement.style.position = "absolute";


    this.inputElement = document.createElement("input");
    this.inputElement.type = "file";
    this.inputElement.multiple = this.multiple;

    this.inputElement.addEventListener('change', function () {
      let files = self.inputElement.files;
      let len = files.length;
      if (self.file_queue_limit > 0 && len > self.file_queue_limit) {
        self.file_queue_error_handler(files[0], AjaxUpload.QUEUE_ERROR.QUEUE_LIMIT_EXCEEDED, 'queue limit exceeded');
      } else {
        for (let i = 0; i < len; i++) {
          files[i].id = "file_" + self.instance_id + "_" + self.numFilesSelected++;
          if (!self.validateFileSize(files[i])) continue;
          if (!self.validateFileType(files[i])) continue;
          self.fileQueue(files[i]);
        }
      }
      self.fileDialogComplete(len);
      self.formElement.reset();
    });

    this.formElement.appendChild(this.inputElement);

    document.body.appendChild(this.formElement);
    this.buttonElement.addEventListener('click', function () {
      self.inputElement.click();
    });
  };

  this.validateFileSize = function (file) {
    try {
      if (file.size > this.fileSizeLimitBytes) {
        this.file_queue_error_handler(file, AjaxUpload.QUEUE_ERROR.FILE_EXCEEDS_SIZE_LIMIT, 'file exceeds size limit');
        return false;
      }
      return true;
    } catch (e) {
      this.file_queue_error_handler(file, AjaxUpload.QUEUE_ERROR.ZERO_BYTE_FILE, 'zero byte file');
      return false;
    }
  };

  this.validateFileType = function (file) {
    if (this.file_types === '*') return true;

    let pattern = new RegExp(".(" + this.file_types + ")$");
    if (!pattern.test(file.name.toLowerCase())) {
      this.file_queue_error_handler(file, AjaxUpload.QUEUE_ERROR.INVALID_FILE_TYPE, 'invalid file type');
      return false;
    }
    return true;
  };

  this.fileQueue = function (file) {
    this.numFilesQueued++;
    this.queue.push(file);
    this.file_queued_handler(file);
  };

  this.fileDialogComplete = function (length) {
    this.file_dialog_complete_handler(length, this.numFilesQueued, this.queue.length);
  };

  this.startUpload = function () {

    if (this.uploading || this.queue.length === 0) return;
    let self = this;
    let file = this.queue.shift();

    if (this.upload_url === "") {
      this.upload_error_handler(file, AjaxUpload.UPLOAD_ERROR.MISSING_UPLOAD_URL, 'miss upload url');
      return;
    }

    let formData = new FormData();
    for (let key in this.post_params) {
      formData.append(key, this.post_params[key])
    }
    formData.append(this.file_post_name, file);
    let request = new XMLHttpRequest();
    request.open("POST", this.upload_url);

    request.upload.addEventListener("progress", function (e) {
      self.upload_progress_handler(file, e["loaded"], e["total"]);
    });
    this.uploading = true;
    request.addEventListener("load", function () {
      self.uploading = false;
      self.upload_success_handler(file, request.responseText);
      self.upload_complete_handler(file);
    });
    request.addEventListener("error", function () {
      self.uploading = false;
      self.upload_error_handler(file, AjaxUpload.UPLOAD_ERROR.UPLOAD_FAILED, 'The transfer has been canceled by the user.');
    });
    request.addEventListener("abort", function () {
      self.uploading = false;
      self.upload_error_handler(file, AjaxUpload.UPLOAD_ERROR.FILE_CANCELLED, 'The transfer has been canceled by the user.');
    });
    self.upload_start_handler(file);
    request.send(formData);

  };

  this.cancelUpload = function (fileId) {
    for (let i = 0, len = this.queue.length; i < len; i++) {
      if (fileId === this.queue[i].id) {
        this.numFilesQueued -= 1;
        this.queue.splice(i, 1);
      }
    }
  };

  this.log = function (message) {
    if (this.debug) {
      console.log(message);
    }
  };
  this.init(settings);
}

export default Html5Upload;