import Html5Upload from './Html5Upload.js'

let AjaxUpload = {

  QUEUE_ERROR: {
    QUEUE_LIMIT_EXCEEDED: -100,
    FILE_EXCEEDS_SIZE_LIMIT: -110,
    ZERO_BYTE_FILE: -120,
    INVALID_FILE_TYPE: -130
  },

  buttonAction: {
    SELECT_FILE: -100,
    SELECT_FILES: -110,
    START_UPLOAD: -120
  },
  UPLOAD_ERROR: {
    HTTP_ERROR: -200,
    MISSING_UPLOAD_URL: -210,
    IO_ERROR: -220,
    SECURITY_ERROR: -230,
    UPLOAD_LIMIT_EXCEEDED: -240,
    UPLOAD_FAILED: -250,
    SPECIFIED_FILE_ID_NOT_FOUND: -260,
    FILE_VALIDATION_FAILED: -270,
    FILE_CANCELLED: -280,
    UPLOAD_STOPPED: -290
  },
  CURSOR: {
    ARROW: -1,
    HAND: -2
  },
  WINDOW_MODE: {
    WINDOW: "window",
    TRANSPARENT: "transparent",
    OPAQUE: "opaque"
  },
  instanceNum: 0,
  createUploader: function (settings) {
    settings.instance_id = ++this.instanceNum;
    if (settings['mode'] === undefined) {
      settings['mode'] = window.FormData === undefined ? 'flash' : 'html5';
    }
    settings['fileSizeLimit'] = settings['file_size_limit'];
    return new Html5Upload(settings, AjaxUpload);
  }
};

export default AjaxUpload;
